<template>
  <div class="deadname content">

    <div class="">
      <p class="title lores s21">
        No you did not end up on the wrong website.
      </p>
      <p class="explainer lores s12">
        You were reffered to this website via <Redacted secret="my deadname"/><br>
        My name is Liara and my pronouns are {{pronouns}}.
      </p>
      <div class="spaced-links">
        <a class="lores s12" href="https://en.wikipedia.org/wiki/Deadnaming" target="_blank">what is deadnaming?</a>
        <a class="lores s12" :href="pronounsPage" target="_blank">what does {{pronouns}} mean?</a>
        <br>
        <router-link class="lores s12" to="/">liara.io</router-link>
        <router-link class="lores s12" to="/work">my work</router-link>
        <router-link class="lores s12" to="/bruch">my music</router-link>
      </div>
    </div>

  </div>
</template>

<script>
import Redacted from '@/components/Redacted.vue'

export default {
  name: 'Deadname',
  props: {
    pronouns: String,
    pronounsPage: String
  },
  components: {
    Redacted
  }
}
</script>

<style lang="scss" scoped>
.spaced-links a {
  display: inline-block;
}

.spaced-links a:not(:last-of-type) {
  margin-right: 1rem;
}

@media only screen and (max-width: 720px) {
  .spaced-links a {
    margin-right: 0 !important;
    display: block;
  }
}
</style>
